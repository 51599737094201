import styles from "./form.module.css"

const AddNewMemberForm = ({setIsOpen}) => {
    const closeForm = () => setIsOpen(false);

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new member</div>
                <img className={styles.cancel} onClick={closeForm}src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default}></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Name
                    </div>
                    <input className={styles.fieldValueName} placeholder="Enter Team Member Name"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Email
                    </div>
                    <input className={styles.fieldValue} placeholder="Enter Email Id"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Role
                    </div>
                    {/* <input className={styles.fieldValue} placeholder="Enter Email Id"></input> */}
                    <div className={styles.memberRole}>
                        <div className={styles.roleAdmin}>
                            Admin
                        </div>
                        <div className={styles.roleMember}>
                            Member
                        </div>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Division
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown}>
                            <option className={styles.fieldValueDropdown}>Default - Littleton</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>


                <div className={styles.continue}>
                    Send Invite
                </div>
            </div>
        </div>

    )
}

export default AddNewMemberForm;