import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import DefaultSpinner from "../../atoms/spinner/Spinner";
import DetailedPractice from "./../../organisms/PracticeDetail";

import Heading from "./components/heading/Heading";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Tabs from "./components/tabs/Tabs";

import getMemberList from "./practiceDetail.async";
import { MEMBERS_STATUS } from "./practiceDetail.constants";

import styles from "./practiceDetail.module.css";
import useToast from "./customHooks/useToast";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";

const PracticeDetail = (props) => {

    const { practiceId, practiceName } = useParams();

    const [activeMemberList = [], setActiveMemberList] = useState();

    const [invitedMemberList = [], setInvitedMemberList] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const { displayToast, toastMessage, toastType, updateToast, removeToast } = useToast();

    useEffect(() => {
        getMemberList(practiceId, MEMBERS_STATUS.INVITED, setInvitedMemberList);
        getMemberList(practiceId, MEMBERS_STATUS.ACTIVE, setActiveMemberList);
    }, []);

    return (
        <>
            {isLoading && <DefaultSpinner className={styles.spinnerContainer}/>}
            {displayToast && 
                <BasicToast
                    message={toastMessage}
                    type={toastType}
                    display={displayToast}
                    handleToastToggle={removeToast}
                 />
            }
            <div className={styles.mainContainer}>
            <PageHeaderContext practiceName={practiceName} />
            <Heading practiceName={practiceName} />
            <Tabs updateToast={updateToast} memberList={activeMemberList.concat(invitedMemberList)}  setIsLoading={setIsLoading} />
        </div>

        </>

    )
}

export default PracticeDetail