import { useState } from "react";
import cx from "classnames";

import { getWebsiteCheckAction, hasFields } from "./form.helpers";
import { MANDATORY_FIELDS } from "./form.fields";

import styles from "./form.module.css"

const AddNewPracticeFormStepOne = ({setIsOpen, setStepCounter, updateFormValues, formValues}) => {

    const [errorStatus, setErrorStatus] = useState({});

    const buttonActiveStatus = hasFields(MANDATORY_FIELDS[0],formValues);
    
    const closeForm = () => {
        setStepCounter("ONE")
        setIsOpen(false)
    };

    const changeStep = () => {
        if(!buttonActiveStatus){
            return;
        }
        setStepCounter("TWO")
    };

    const handleStateUpdate = (type) =>(event)=>{
        event.preventDefault();
        switch(type){
            case "practiceWebsite":
                if(!getWebsiteCheckAction(event.target.value)){
                    setErrorStatus((prev)=>{
                        return {
                            ...prev,
                            [type]: true
                        }
                    })
                    return;
                }
                else{
                    setErrorStatus((prev)=>{
                        return {
                            ...prev,
                            [type]: false
                        }
                    })
                }
                break;
            default:
                break;
        }
        updateFormValues({type,value: event.target.value})
    }

    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new practice</div>
                <img className={styles.cancel} onClick={closeForm}src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default}></img>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.step}>
                    Step 1/3
                </div>
                <div className={styles.stepBar}>
                    <div className={styles.stepBarOne}></div>
                    <div className={styles.stepBarTwo}></div>
                    <div className={styles.stepBarThree}></div>
                </div>
                <div className={styles.practiceDetail}>
                    Practice Detail
                </div>
                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Practice Name
                    </div>
                    <input className={styles.fieldValueName} value={formValues?.["practiceName"]} onChange={handleStateUpdate('practiceName')} placeholder="Enter practice name"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Type of Practice
                    </div>
                    <div className={styles.dropdown}>
                        <select value={formValues?.["practiceType"]} className={styles.fieldValueDropdown} placeholder={"Select The Practice Type"} onChange={handleStateUpdate('practiceType')}>
                            <option className={styles.fieldValueDropdown}>Private Practice</option>
                            <option className={styles.fieldValueDropdown}>Public Practice</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Website
                    </div>
                    <input className={styles.fieldValue} value={formValues?.["practiceWebsite"]}  onChange={handleStateUpdate('practiceWebsite')} placeholder="Enter Patient Official Website"></input>
                    {errorStatus?.["practiceWebsite"] && <div className={styles.activeError}>Please Enter a valid website</div>}
                </div>

                <div className={cx(styles.continue,{[styles.nonActiveButton]: !buttonActiveStatus})} onClick={changeStep}>
                    Continue
                </div>
            </div>
        </div>

    )
}

export default AddNewPracticeFormStepOne;