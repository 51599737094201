import { API_STATUS_CODES, BASE_URL } from "../../../../constants/api";

const handleClick = async (navigate, password) => {

    const urlParams = new URLSearchParams(window.location.search);

    const email = urlParams.get('email');

    const token = urlParams.get('token');

    const request = await fetch(`${BASE_URL}/api/dashboard/users/create`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            password: password,
            token: token
        })
    });

    if (request.status === API_STATUS_CODES.SUCCES) {
        navigate("/login");
    }

}

export default handleClick;
