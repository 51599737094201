import React, { useLayoutEffect } from "react";
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, redirect, Navigate } from "react-router-dom";
import Home from "./components/screens/Home";
import HomePage from "./components/screens/home/index";
import ManageActionPlan from "./components/screens/ManageActionPlan";
import ActionPlan from "./components/screens/ActionPlan";
import SignUp from "./components/organisms/signUp/SignUp";
import Login from "./components/screens/login"
import ResetPassword from "./components/screens/resetPassword";
import PracticeCreateAccount from "./components/screens/practiceCreateAccount/PracticeCreateAccount";
import PracticeAccountSetup from "./components/organisms/practiceAccountSetup";
import DashBoard from "./components/screens/dashBoard/DashBoard";
import CommonHeader from "./components/organisms/commonHeader";
import CommonSidebar from "./components/organisms/commonSidebar";
import ImperativeRoutes from "./ImperativeHandler";
import UserInviteFlow from "./components/organisms/userInviteFlow/UserInviteFlow";
// import { BASE_URL } from "./components/constants/api";
import GlucoseTirChart from "./components/charts/GlucoseTirChart";
import LineChart from "./components/charts/LineChart";
import PieChart from "./components/charts/PieChart/PieChart";
import PatientProfile from "./components/organisms/patientProfile";
import SingleElementPieChart from "./components/charts/SingleElementPieChart";
import VerticalBarChart from "./components/charts/VerticalBarChart";
import UserSetting from "./components/organisms/userSetting/UserSetting";
import Patients from "./components/screens/patients";
import PatientInviteModal from "./components/organisms/patientInviteModal";
import PatientInviteConnect from "./components/organisms/patientInviteConnect"
import ChangePasswordModal from "./components/organisms/changePasswordModal/ChangePasswordModal";
import TrendAnalysis from "./components/charts/TrendAnalysis/TrendAnalysis";
import InvitePatientCard from "./components/organisms/InvitePatientCard";
import PracticeManagement from "./components/screens/practiceManagement/PracticeManagement";
import PracticeManagementCommonHeader from "./components/organisms/practiceManagementCommonHeader/PracticeManagementCommonHeader";
import PracticeManagementCommonSidebar from "./components/organisms/practiceManagementCommonSidebar/PracticeManagementCommonSidebar";
import PracticeDetail from "./components/screens/practiceDetail/PracticeDetail";
import RrxCoach from "./components/screens/rrxCoach/RrxCoach";
import MemberDetail from "./components/screens/memberDetail/MemberDetail";

export default function MainApp() {

  const [username,setUsername]=useState();

  useLayoutEffect(()=>{
    apiCall()
  },[])

  const apiCall=async()=>{
    // const response=await fetch(`${BASE_URL}/user/nanda.atankshu@gmail.com`,{
    //   method:'GET'
    // })
    // const json= await response.json();
    // setUsername(json.username)
    // console.log(json);
  }

  const localUsername = window?.localStorage?.getItem("username");
 
  // let internalId = "6tgtfttf65rfttftr"
  return (
      <Router>
            <Routes>
              <Route exact path="/invitePatientConnect" element={<PatientInviteConnect />} />
              {/* <Route exact path="/invitePatientConnect" element={<InvitePatientCard />} /> */}
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signUp" element={<SignUp />} />
              <Route exact path="/changePassword" element={<ResetPassword />} />
              <Route exact path="/createAccount" element={<PracticeCreateAccount />} />
              <Route exact path="/" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><Patients /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/patient" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><HomePage /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/manageActionPlan" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><ManageActionPlan /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/actionPlan" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><ActionPlan /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/dashBoard" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><DashBoard /></CommonSidebar></CommonHeader>} />} />
              <Route exact path={`/patients/:id`} element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><PatientProfile /></CommonSidebar></CommonHeader>} />} />
              {/* <Route exact path="/rs" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><VerticalBarChart /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/r" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><SingleElementPieChart /></CommonSidebar></CommonHeader>} />} /> */}
              <Route exact path="/settings" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><UserSetting /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/invitePatient" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><Patients /></CommonSidebar></CommonHeader>} />} />
              {/* <Route exact path="/pi" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><PatientInviteModal /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/cp" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><ChangePasswordModal /></CommonSidebar></CommonHeader>} />} /> */}
              <Route exact path="/splineChart" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><TrendAnalysis /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/superuser" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><PracticeManagement /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path={`/superUser/:practiceId/:practiceName`} element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><PracticeDetail /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path="/rrxCoach" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><RrxCoach /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path="/superUser/practice/member" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><MemberDetail></MemberDetail></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
            </Routes>
      </Router>
  );
}
