import { useState } from "react";

import Members from "../members/Members";
import Overview from "../overview/Overview";
import Patients from "../patients/Patients";

import { TABS } from "./tabs.constants";

import styles from "./tabs.module.css";


const Tabs = ({memberList, setIsLoading, updateToast}) => {

    const [currentTab, setCurrentTab] = useState(TABS.OVERVIEW);

    const handleClick = (currentTab) => {
        setCurrentTab(currentTab);
    };

    const showTab = (tab,memberList, setIsLoading) => {
        switch(tab) {
            case TABS.ACTIVE: 
                return <Overview />;
            
            case TABS.MEMBERS: 
                return <Members memberList={memberList}/>;

            case TABS.PATIENTS: 
                return <Patients setIsLoading={setIsLoading} updateToast={updateToast}/>;
          
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.tabOptions}>
                <div className={currentTab == TABS.OVERVIEW ? styles.tabActive : styles.tab}
                    onClick={() => handleClick(TABS.OVERVIEW)}>Overview</div>
                <div className={currentTab == TABS.MEMBERS ? styles.tabActive : styles.tab}
                    onClick={() => handleClick(TABS.MEMBERS)}>Team Members</div>
                <div className={currentTab == TABS.PATIENTS ? styles.tabActive : styles.tab}
                    onClick={() => handleClick(TABS.PATIENTS)}>Patient List</div>
            </div>
            {showTab(currentTab, memberList, setIsLoading)}
        </div>


    )
}

export default Tabs;
