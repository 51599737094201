import styles from "./form.module.css"

const AddNewPracticeFormStepTwo = ({setOpen}) => {
    const closeForm = () => setOpen(false);
    return (

        <div className={styles.container}>
            <div className={styles.upperContainer}>
                <div className={styles.formName}>Add new worksite</div>
                <img className={styles.cancel} src={require("../../../../../assets/images/cancel/transparent_cacel.svg").default} onClick={closeForm}></img>
            </div>
            <div className={styles.bottomContainer}>
                

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Country
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown}>
                            <option className={styles.fieldValueDropdown}>United States of America</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Address 1
                    </div>
                    <input className={styles.fieldValue} placeholder="Enter Practice Street Address"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Address 2
                    </div>
                    <input className={styles.fieldValue} placeholder="Enter Practice Street Address"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        City
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown}>
                            <option className={styles.fieldValueDropdown}>Littleton</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        State
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown}>
                            <option className={styles.fieldValueDropdown}>New York</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Zipcode
                    </div>
                    <input className={styles.fieldValue} placeholder="Enter Practice Zipcode"></input>
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldName}>
                        Admin
                    </div>
                    <div className={styles.dropdown}>
                        <select className={styles.fieldValueDropdown}>
                            <option className={styles.fieldValueDropdown}>Choose Admin for the worksite</option>
                        </select>
                        <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                    </div>
                </div>

                <div className={styles.worksiteId}>
                    <img className={styles.worksiteIdInfo} src={require("../../../../../assets/images/alert_message.svg").default}></img>
                    <div className={styles.workisteIdContent}>Worksite ID</div>
                    <div className={styles.worksiteIdValue}>pg-littleton01</div>
                </div>

                <div className={styles.continue}>
                    Continue
                </div>
            </div>
        </div>

    )
}

export default AddNewPracticeFormStepTwo;