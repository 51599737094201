import styles from "./form.module.css";
import Header from "./components/Header";
import Fields from "./components/Fields";
import Button from "./components/Button";
import { useState } from "react";
import { useEffect } from "react";


const Form = () => {

    const [password, setPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    const [isPasswordsMatching, setIsPasswordsMatching] = useState(true);

    const urlParams = new URLSearchParams(window.location.search);

    const email = urlParams.get('email');

    const token = urlParams.get('token');

    useEffect(() => {
        if (password !== confirmPassword || password?.length==0) { 
            setIsPasswordsMatching(true) 
        } else { 
            setIsPasswordsMatching(false) 
        }
    }, [confirmPassword, password]);


    return (
        <div className={styles.container}>
            <Header></Header>
            <Fields setPassword={setPassword} setConfirmPassword={setConfirmPassword} setIsPasswordsMatching={setIsPasswordsMatching} isPasswordsMatching={isPasswordsMatching} password={password} confirmPassword={confirmPassword} email={email}></Fields>
            <Button password={password} isPasswordsMatching={isPasswordsMatching}/>
        </div>
    )
}

export default Form;