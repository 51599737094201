import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";

import styles from "./formContent.module.css";

const FormField = ({
        checkErrorState=()=>{},
        displayError = true,
        name,
        PlaceHolder,
        errorTypes,
        errorProps,
        inputFieldRow,
        CustomInput,
        fieldClassname="",
        handleFieldChange,
        value,
        fieldType,
        className="",
        label,
        labelClassName="",
        fieldError,
        ErrorComponent=()=><></>, 
        PrefixComponent = ()=><></>, 
        SuffixComponent = ()=><></>,
        ...rest
    }) =>{

    const [inputValue,setInputValue] = useState(value);

    useEffect(()=>{
        setInputValue(value)
    },[value])

    const handleInputFieldFocus = useCallback((event)=>{
        event.preventDefault();
        handleFieldChange({label: name})(event?.target?.value);
        checkErrorState(event?.target?.value, name, errorTypes);
    },[setInputValue,handleFieldChange,checkErrorState]);

    const handleInputFieldChange = useCallback((event) =>{
        event.preventDefault();
        setInputValue(event?.target?.value)
        handleFieldChange({label: name})(event?.target?.value);
        checkErrorState(event?.target?.value, name, errorTypes);
    },[setInputValue,handleFieldChange,checkErrorState])

    const handleFieldBlur = useCallback((event) =>{
        event?.preventDefault();
        handleFieldChange({label: name})(event?.target?.value);
        checkErrorState(event?.target?.value, name, errorTypes);
    },[handleFieldChange,checkErrorState]);

    return (
        <div className={cx(styles.fieldContainer,className)} key={name}>
            <div className={cx(styles.label,labelClassName)}>{label}</div>
            <div className={cx(styles.inputErrorContainer, inputFieldRow)}>
                <div className={styles.inputFieldContainer} key={name}>
                    {!CustomInput && <><PrefixComponent/>
                        <input className={cx(styles.inputField,fieldClassname,{[styles.errorInput]:(fieldError!= undefined && Object.values(fieldError).length>0)})} placeholder={PlaceHolder} onBlur={handleFieldBlur} type={fieldType} value={inputValue} onChange={handleInputFieldChange}  onFocus={handleInputFieldFocus} {...rest}/>
                    <SuffixComponent />
                    </>
                    }
                    {CustomInput && <CustomInput checkErrorState={checkErrorState} errorTypes={errorTypes} name={name} errorClassname={ displayError ? styles.errorInput : ""} placeholder={PlaceHolder} onFocus={handleInputFieldFocus} className={cx(styles.inputField,fieldClassname)} onBlur={handleFieldBlur} type={fieldType} value={value ?? inputValue} onChange={handleInputFieldChange} fieldError={fieldError} handleFieldChange={handleFieldChange} {...rest}/>}
                </div>
                { fieldError != undefined && <ErrorComponent {...errorProps} errorTypes={errorTypes} fieldError={fieldError} />}
            </div>
        </div>
    )
}

export default FormField;