import { PRACTICE_CREATE_ACCOUNT_FIELDS } from "./practiceCreateAccountCard.fields";
import styles from "./practiceCreateAccountCard.module.css";

import FormManager from "../../reusables/formManager/FormManager";

const getCreateAccountPage =(dispatch,handleSetUpCompletedAction,handlePageSubmit,handleFormValueChange,handleFormSubmit,handlePhoneOtpStage)=>[{
    Component:FormManager,
    formHeaderProps:{
        primaryHeaderText: "Basic Details",
        containerClassname: styles.formHeaderProps
    },
    formContent : PRACTICE_CREATE_ACCOUNT_FIELDS[0],
    formFooterProps:{
        butttonText : "Continue",
        enabledClassname: styles.enabledClassname
    },
    formBodyClassname: styles.formBodyContainer,
    formContainerProps:{
        containerClassname : styles.formContainer
    },
    handleValueChange: (payload)=>{
        handleFormValueChange(payload);
    },
    formSubmitAction: (payload)=> {
        handlePageSubmit(payload);
        // dispatch({actionType:actionType.MOVE_TO_NEXT_PAGE})
    },
},
{
    Component:FormManager,
    formHeaderProps:{
        primaryHeaderText: "Professional Details",
        containerClassname: styles.formHeaderProps
    },
    formContent: PRACTICE_CREATE_ACCOUNT_FIELDS[1],
    formFooterProps:{
        butttonText : "Continue",
        enabledClassname: styles.enabledClassname
    },
    formBodyClassname: styles.formBodyContainer,
    formContainerProps:{
        containerClassname : styles.formContainer
    },
    handleValueChange: (payload)=>{
        handleFormValueChange(payload);
    },
    formSubmitAction: (payload)=> {
        handlePageSubmit(payload);
        // dispatch({actionType:actionType.MOVE_TO_NEXT_PAGE})
    },
},
{
    Component:FormManager,
    formHeaderProps:{
        primaryHeaderText: "Contact Details",
        containerClassname: styles.formHeaderProps
    },
    formContent: PRACTICE_CREATE_ACCOUNT_FIELDS[2],
    formFooterProps:{
        butttonText : "Continue",
        enabledClassname: styles.enabledClassname
    },
    formBodyClassname: styles.formBodyContainer,
    formContainerProps:{
        containerClassname : styles.formContainer
    },
    handleValueChange: (payload)=>{
        handleFormValueChange(payload);
    },
    formSubmitAction: (payload)=> {
        handleFormSubmit(payload);
        // dispatch({actionType:actionType.MOVE_TO_NEXT_PAGE})
    },
},
{
    Component:FormManager,
    formHeaderProps:{
        primaryHeaderText: "Multi-Step Verification",
        containerClassname: styles.formHeaderProps
    },
    formContent: PRACTICE_CREATE_ACCOUNT_FIELDS[3],
    formFooterProps:{
        butttonText : "Generate OTP",
        enabledClassname: styles.enabledClassname
    },
    formBodyClassname: styles.formBodyContainer,
    formContainerProps:{
        containerClassname : styles.formContainer
    },
    handleValueChange: (payload)=>{
        handleFormValueChange(payload);
    },
    formSubmitAction: (payload)=> {
        handlePhoneOtpStage(payload);
    },
},
{
    Component:FormManager,
    formHeaderProps:{
        primaryHeaderText: "Multi-Step Verification",
        containerClassname: styles.formHeaderProps
    },
    formContent: PRACTICE_CREATE_ACCOUNT_FIELDS[4],
    formFooterProps:{
        butttonText : "Finish",
        enabledClassname: styles.enabledClassname
    },
    formBodyClassname: styles.formBodyContainer,
    formContainerProps:{
        containerClassname : styles.formContainer
    },
    handleValueChange: (payload)=>{
        handleFormValueChange(payload);
    },
    formSubmitAction: ()=> {
        handleSetUpCompletedAction();
    },
}
]

export {
    getCreateAccountPage
}