import { BASE_URL } from "../../constants/api";
import { TABS } from "./components/tabs/tabs.constants";

const getMemberList = async (practiceId, practiceTab, setMemberList) => {

    try {
        const memberListRequest = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMembers/${practiceId}/${practiceTab}`, {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });
        const memberListResponse = await memberListRequest.json();
        setMemberList(memberListResponse.dashboardUserDataList);
    }
    catch (e) {
        console.log('error is : ', e);
    }

}

export default getMemberList;