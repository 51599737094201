import styles from "./overview.module.css"

const Overview = () => {

    return (
        <div className={styles.mainContainer}>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Personal Detail</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Name</div>
                            <div className={styles.fieldValue}>Maryanne Bruno</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Contact Number</div>
                            <div className={styles.fieldValue}>(212) 555-6799</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Ethnicity</div>
                            <div className={styles.fieldValue}>Hispanic or Latino</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>Email</div>
                            <div className={styles.fieldValue}>maryanne.bruno@pediatrix.com</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Language</div>
                            <div className={styles.fieldValue}>English, French</div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Professional Details</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Speciality</div>
                            <div className={styles.fieldValue}>Perinatal Nurse Practitioner (PNNP)</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>Credentials</div>
                            <div className={styles.fieldValue}>CNM-Certified Nurse Midwife</div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Contact Detail</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Work Email</div>
                            <div className={styles.fieldValue}>maryanne.bruno@pediatrix.com</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>City</div>
                            <div className={styles.fieldValue}>Littleton</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>State</div>
                            <div className={styles.fieldValue}>Colorado</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    )
}

export default Overview;