import styles from "./tabs.module.css"
import { useState } from "react";
const Tabs = () => {

    const [isClicked, setIsClicked] = useState("Active");

    const handleClick = (currentValue) => {
        setIsClicked(currentValue);
    };

    return (

        <div className={styles.tabOptions}>
            <div className={isClicked == "Active" ? styles.tabActive : styles.tab}
                onClick={() => handleClick("Active")}>Active</div>
            <div className={isClicked == "Invited" ? styles.tabActive : styles.tab}
                onClick={() => handleClick("Invited")}>Invited</div>
            <div className={isClicked == "Archived" ? styles.tabActive : styles.tab}
                onClick={() => handleClick("Archived")}>Archived</div>
        </div>

    )
}

export default Tabs;
