const MEMBERS_STATUS = {
    ACTIVE : "ACTIVE",
    INVITED : "INVITED",
    ARCHIVED : "ARCHIVED"
}

const TOAST_TYPE_CONSTANTS ={
    SUCCESS : "SUCCESS",
    FAILED : "FAILED"
}

const TOAST_TYPE ={
    SUCCESS : "success",
    FAILED : 'failed'
}

const PRACTICE_FORM_TOAST_MESSAGE ={
    SUCCESS : "Successfully Added a Patient",
    FAILED : 'Failed To Add a Patient'
}

export {
    MEMBERS_STATUS,
    TOAST_TYPE,
    PRACTICE_FORM_TOAST_MESSAGE,
    TOAST_TYPE_CONSTANTS
};