import Table from "./components/table/Table";
import Search from "./components/search/Search"

const Members = ({memberList}) => {

    return (
        <div>
            <Search></Search>
            <Table listItems={memberList}></Table>
            
        </div>


    )
}

export default Members;
