import { map } from "lodash";
import { getTextInitials } from "../../../../../../helpers/TextManipulations.helpers";

import { CARE_PRO_USER_TYPE} from "./table.constants";

import styles from "./table.module.css";
import { MEMBERS_STATUS } from "../../../../practiceDetail.constants";

const Table = ({ listItems }) => {

    return (

        <div className={styles.practiceListContainer}>
            <div className={styles.tableHeader}>
                <div className={styles.tableColumn}>NAME<img src={require("../../../../../../../assets/images/sort-icon.svg").default} className={styles.searchLogo}></img></div>
                <div className={styles.tableColumn}>EMAIL</div>
                <div className={styles.tableColumn}>ROLE</div>
                <div className={styles.tableColumn}>STATUS</div>
                <div className={styles.tableColumnAction}>ACTION</div>
            </div>
            <div className={styles.tableColumnContainer}>
                {
                    map(listItems, (item) => {
                        const { id = "", firstName = "", lastName = "", email = "", role = "", memberInviteStatus = "", speciality = "" } = item || {};
                        return (
                            <div className={styles.tableColumnData}>
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.avatar}>
                                        <div className={styles.avatarData}>
                                            {getTextInitials(firstName + " " + lastName)}
                                        </div>
                                    </div>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {firstName + " " + lastName}
                                        </div>
                                        <div className={styles.practiceUrl}>
                                            {speciality}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {email}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.roleStyle}>
                                            {role === CARE_PRO_USER_TYPE.RENEWRX_CARE_PRO || role === CARE_PRO_USER_TYPE.NON_RENEWRX_CARE_PRO ? "Member" : "Admin"}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.nameAndUrlContainer}>
                                        {memberInviteStatus === MEMBERS_STATUS.ACTIVE && <div className={styles.statusStyle}>
                                            Active
                                        </div>}
                                        {memberInviteStatus === MEMBERS_STATUS.INVITED && <div className={styles.statusStyleInvite}>
                                            Invite Sent
                                        </div>}
                                    </div>
                                </div>

                                <div className={styles.tableColumnDataContainer}>

                                    <div className={styles.resendInvite}>
                                        <div className={styles.resendInviteText}>
                                            View Details
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )
                    })
                }
            </div>
        </div>




    )
}

export default Table;
