import React from "react";
import cx from "classnames";
import {map} from "lodash";

import styles from "./baseTabs.module.css";

const BaseTabs = ({tabs})=>{
    return (
        <div className={styles.container}>
            {map(tabs,(tab)=>{
                const { name =''} = tab;
                return (
                    <div className={cx(styles.tabItem,styles.tabTypo)}>
                        {name}
                    </div>
                )
            })}
        </div>
    )
}

BaseTabs.defaultProps={
    tabs : [],
}

export default BaseTabs