import { useState } from "react";
import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";

import AddNewPracticeFormStepTwo from "../newWorksiteForm/AddNewPracticeFormStepTwo";

import styles from "./heading.module.css";

const Heading = ({practiceName}) => {

    const [options, setOptions] = useState(false);

    const [open, setOpen] = useState(false);

    return (

        <div className={styles.heading}>
            <div className={styles.practiceLogo}>
                {getTextInitials(practiceName)}
            </div>
            <div className={styles.practiceName}>
                {practiceName}
            </div>
            <div className={styles.buttonGroup}>
                <div className={styles.buttonExport} onClick={()=>{setOpen(true);}}>Add New Worksite</div>
                {open && <div className={styles.formBackground}>
                    <AddNewPracticeFormStepTwo setOpen={setOpen} className={styles.addNewPracticeFormStepOne}></AddNewPracticeFormStepTwo>
                    </div>}
                <div className={styles.buttonExport} onClick={()=>{setOptions(!options)}}><img src={require("../../../../../assets/images/more_options.svg").default}/></div>
                {options && <div className={styles.moveToArchive}><div className={styles.moveToArchiveContainer}>Move to Archive</div><div className={styles.moveToArchiveSpace}></div></div>}
            </div>
        </div>

    )
}

export default Heading;