import styles from "./overview.module.css"

const Overview = () => {

    return (
        <div className={styles.mainContainer}>

            <div className={styles.practiceInfo}>
                <div className={styles.container}>
                    <div className={styles.practiceDetail}>Practice Detail</div>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Practice Name</div>
                            <div className={styles.fieldValue}>Pediatrix Group of Health</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Practice Type</div>
                            <div className={styles.fieldValue}>Private Practice</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Website</div>
                            <div className={styles.fieldValue}>www.pediatrix.com</div>
                        </div>
                    </div>

                    <div className={styles.practiceEdit}>Edit</div>
                </div>

                <div className={styles.container}>
                    <div className={styles.practiceDetail}>Admin Detail</div>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Admin</div>
                            <div className={styles.fieldValue}>Maryanne Bruno</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Email</div>
                            <div className={styles.fieldValue}>maryanne.bruno@pediatrix</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Contact Number</div>
                            <div className={styles.contactNumber}>Add number</div>
                        </div>
                    </div>

                    <div className={styles.practiceEdit}>Edit</div>
                </div>
            </div>

            <div className={styles.allWorksites}>All Worksites</div>

            <div className={styles.worksite}>
                <div className={styles.worksiteHeadingContainer}>
                    <div className={styles.worksiteHeading}>Worksite 1</div>
                    <div className={styles.worksiteId}>pg-littleton01</div>
                </div>
                <div className={styles.worksiteLayout}>
                    <div className={styles.worksiteFields}>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Country</div>
                            <div className={styles.fieldValue}>United States of America</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Address 2</div>
                            <div className={styles.fieldValue}>-</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>City</div>
                            <div className={styles.fieldValue}>7700 S Broadway, Littleton</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Worksite Admin</div>
                            <div className={styles.contactNumber}>Maryanne Bruno</div>
                        </div>
                    </div>

                    <div className={styles.worksiteFieldsSecondary}>
                        <div className={styles.worksiteField} >
                            <div className={styles.fieldType}>Address 1</div>
                            <div className={styles.fieldValue}>7700 S Broadway, Littleton</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>State</div>
                            <div className={styles.fieldValue}>Colorado</div>
                        </div>
                        <div className={styles.worksiteField}>
                            <div className={styles.fieldType}>Zipcode</div>
                            <div className={styles.fieldValue}>80122</div>
                        </div>
                    </div>

                </div>
                <div className={styles.practiceEdit}>Edit</div>
            </div>

            <div className={styles.practiceInfo}>
                <div className={styles.container}>
                    <div className={styles.practiceDetail}>Worksite 1</div>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Country</div>
                            <div className={styles.fieldValue}>United States of America</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Address 1</div>
                            <div className={styles.fieldValue}>7700 S Broadway, Littleton</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Address 2</div>
                            <div className={styles.fieldValue}>-</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>State</div>
                            <div className={styles.fieldValue}>Colorado</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>City</div>
                            <div className={styles.fieldValue}>7700 S Broadway, Littleton</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Zipcode</div>
                            <div className={styles.fieldValue}>80122</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Worksite Admin</div>
                            <div className={styles.contactNumber}>Maryanne Bruno</div>
                        </div>
                        <div className={styles.buttonGroup}>
                            <div className={styles.practiceEdit}>Edit</div>
                            <div className={styles.practiceDelete}>Delete Worksite</div>
                        </div>
                    </div>
                </div>

                <div className={styles.container}>
                    <div className={styles.practiceDetail}>Worksite 1</div>
                    <div className={styles.fields}>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Country</div>
                            <div className={styles.fieldValue}>United States of America</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Address 1</div>
                            <div className={styles.fieldValue}>7700 S Broadway, Littleton</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Address 2</div>
                            <div className={styles.fieldValue}>-</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>State</div>
                            <div className={styles.fieldValue}>Colorado</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>City</div>
                            <div className={styles.fieldValue}>7700 S Broadway, Littleton</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Zipcode</div>
                            <div className={styles.fieldValue}>80122</div>
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldType}>Worksite Admin</div>
                            <div className={styles.contactNumber}>Maryanne Bruno</div>
                        </div>
                        <div className={styles.buttonGroup}>
                            <div className={styles.practiceEdit}>Edit</div>
                            <div className={styles.practiceDelete}>Delete Worksite</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default Overview;