
const LANGUAGE_OPTIONS = [{
        id:1,
        value : "English",
    },
    {
        id:2,
        value : "French",
    },
    {
        id:3,
        value : "German"
    },
    {
        id:4,
        value : "Mandarin"
    }
]

const ETHNICITY = [{
        id: 1,
        value : "Hispanic or Latino",
        label : "Hispanic or Latino",
    },
    {
        id:2,
        label : "Not Hispanic or Latino",
        value : "Not Hispanic or Latino"
    },
    {
        id:3,
        label : "American Indian or Alaska Native",
        value : "American Indian or Alaska Native"
    },
    {
        id:4,
        label : "Asian",
        value : "Asian"
    },
    {
        id:5,
        label : "Black or African American",
        value : "Black or African American"
    },
    {
        id:6,
        label : "Native Hawaiian or Other Pacific Islander",
        value : "Native Hawaiian or Other Pacific Islander"
    },
    {
        id:7,
        label : "White",
        value : "White"
    },
    {
        id:8,
        label : "Others",
        value : "Others"
    }

]

const STATES= [
    { id: 1, value: 'Alabama' },
    { id: 2, value: 'Alaska' },
    { id: 3, value: 'Arizona' },
    { id: 4, value: 'Arkansas' },
    { id: 5, value: 'California' },
    { id: 6, value: 'Colorado' },
    { id: 7, value: 'Connecticut' },
    { id: 8, value: 'Delaware' },
    { id: 9, value: 'Florida' },
    { id: 10, value: 'Georgia' },
    { id: 11, value: 'Hawaii' },
    { id: 12, value: 'Idaho' },
    { id: 13, value: 'Illinois' },
    { id: 14, value: 'Indiana' },
    { id: 15, value: 'Iowa' },
    { id: 16, value: 'Kansas' },
    { id: 17, value: 'Kentucky' },
    { id: 18, value: 'Louisiana' },
    { id: 19, value: 'Maine' },
    { id: 20, value: 'Maryland' },
    { id: 21, value: 'Massachusetts' },
    { id: 22, value: 'Michigan' },
    { id: 23, value: 'Minnesota' },
    { id: 24, value: 'Mississippi' },
    { id: 25, value: 'Missouri' },
    { id: 26, value: 'Montana' },
    { id: 27, value: 'Nebraska' },
    { id: 28, value: 'Nevada' },
    { id: 29, value: 'New Hampshire' },
    { id: 30, value: 'New Jersey' },
    { id: 31, value: 'New Mexico' },
    { id: 32, value: 'New York' },
    { id: 33, value: 'North Carolina' },
    { id: 34, value: 'North Dakota' },
    { id: 35, value: 'Ohio' },
    { id: 36, value: 'Oklahoma' },
    { id: 37, value: 'Oregon' },
    { id: 38, value: 'Pennsylvania' },
    { id: 39, value: 'Rhode Island' },
    { id: 40, value: 'South Carolina' },
    { id: 41, value: 'South Dakota' },
    { id: 42, value: 'Tennessee' },
    { id: 43, value: 'Texas' },
    { id: 44, value: 'Utah' },
    { id: 45, value: 'Vermont' },
    { id: 46, value: 'Virginia' },
    { id: 47, value: 'Washington' },
    { id: 48, value: 'West Virginia' },
    { id: 49, value: 'Wisconsin' },
    { id: 50, value: 'Wyoming' }
]

const CITIES= [
    { id: 1, value: 'New York City' },
    { id: 2, value: 'Los Angeles' },
    { id: 3, value: 'Chicago' },
    { id: 4, value: 'Houston' },
    { id: 5, value: 'Phoenix' },
    { id: 6, value: 'Philadelphia' },
    { id: 7, value: 'San Antonio' },
    { id: 8, value: 'San Diego' },
    { id: 9, value: 'Dallas' },
    { id: 10, value: 'San Jose' },
    { id: 11, value: 'Austin' },
    { id: 12, value: 'Jacksonville' },
    { id: 13, value: 'Fort Worth' },
    { id: 14, value: 'Columbus' },
    { id: 15, value: 'Charlotte' },
    { id: 16, value: 'San Francisco' },
    { id: 17, value: 'Indianapolis' },
    { id: 18, value: 'Seattle' },
    { id: 19, value: 'Denver' },
    { id: 20, value: 'Washington D.C.' },
    { id: 21, value: 'Boston' },
    { id: 22, value: 'El Paso' },
    { id: 23, value: 'Nashville' },
    { id: 24, value: 'Detroit' },
    { id: 25, value: 'Oklahoma City' },
    { id:26, value: "Other"}
]

export {
    LANGUAGE_OPTIONS,
    ETHNICITY,
    STATES,
    CITIES
}