import { useState } from "react";
import { PRACTICE_FORM_TOAST_MESSAGE, TOAST_TYPE } from "../practiceDetail.constants";

const useToast = ()=>{
    const [displayToast, setDisplayToast] = useState(false);
    const [toastType , setToastType ] = useState(TOAST_TYPE.FAILED);
    const [toastMessage , setToastMessage ] = useState("");

    const updateToast = (type)=>{
        setDisplayToast(true);
        setToastType(TOAST_TYPE[type]);
        setToastMessage(PRACTICE_FORM_TOAST_MESSAGE[type]);
    }

    const removeToast = ()=>{
        setDisplayToast(false);
    }

    return {
        displayToast,
        toastMessage,
        toastType,
        updateToast,
        removeToast
    }
}

export default useToast;