import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';

import styles from "./singleElementPieChart.module.css";

import "./chartStyles.module.css";

// Initialize Highcharts modules
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const options = {
  chart: {
    type: 'pie',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false
  },
  title:{
    text : null,
  },
  plotOptions: {
    pie: {
      innerSize: '70%',
      size: '100%',
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [{
    type: 'pie',
    data: [
      ['Chrome', 61.41],
      ['Firefox', 11.84],
      ['Edge', 4.67],
      ['Safari', 4.18],
      ['Other', 17.9]
    ]
  }],
  credits : {
    enabled : false
  }
};

const DonutChart = () => <div className={styles.container}><HighchartsReact highcharts={Highcharts} options={options} /></div>;

export default DonutChart;
