import styles from "./heading.module.css";
import AddNewMemberForm from "../form/AddNewMemberForm";
import { useState } from "react";

const Heading = () => {
    const [open, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(!open);

    return (

        <div className={styles.heading}>
            <div className={styles.headingStyle}>RRX Coach</div>
            <div className={styles.buttonGroup}>
                <div className={styles.buttonExport}>Export</div>
                <div className={styles.buttonAddNewPractice} onClick={openForm}>Add New Member</div>
                {open && <div className={styles.formBackground}>
                <AddNewMemberForm setIsOpen={setIsOpen} className={styles.addNewPracticeFormStepOne}/>
                    </div>}
            </div>
        </div>

    )
}

export default Heading;