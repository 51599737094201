import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Heading from "./components/heading/Heading";
import Tabs from "./components/tabs/Tabs";
import styles from "./memberDetail.module.css";
const MemberDetail = () => {



    return (
        <div className={styles.mainContainer}>
             <PageHeaderContext></PageHeaderContext>
            <Heading></Heading>
            <Tabs></Tabs>
        </div>
    )
}

export default MemberDetail;