import React, { useEffect } from "react";
import {map} from "lodash";
import cx from "classnames";

import { getCreateAccountPage } from "../../practiceCreateAccountCard.pages";

import styles from "./practiceCardContainer.module.css";

const CardLeftContainer = ({currentPage =1, handleSetUpCompletedAction, dispatch,handlePageSubmit,handleFormValueChange,handleFormSubmit,handlePhoneOtpStage,...rest}) =>{

    const currentSpan = [1,2,3,4,5];

    const formProps = getCreateAccountPage(dispatch,handleSetUpCompletedAction,handlePageSubmit,handleFormValueChange,handleFormSubmit,handlePhoneOtpStage)[currentPage-1];
    const {Component = ()=><></>} = formProps || {}

    return (
        <div className={styles.leftCardContainer}>
            <div className={styles.leftCardHeader}>
                <div className={styles.leftCardHeaderText}>
                    Steps {currentPage}/5
                </div>
                <div className={styles.progressStepperContainer}>
                    {map(currentSpan,(val)=>{
                        return (
                            <div className={cx(styles.nonActiveProgressStepper,{[styles.progressStepper]: val <= currentPage})}>
                            </div>
                        )
                    })}
                </div>
            </div>
            
            <Component {...rest} formProps={formProps} />
        </div>
    )
}

CardLeftContainer.defaultProps+{
    currentPage: 1,
}

export default CardLeftContainer;