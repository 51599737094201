import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = () => {

    return (
        
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div className={styles.subHeadingPractice}>Practice Management  /  Pediatrix Group of Health  </div>/  Maryanne Bruno</div>
            </div>

    )
}

export default PageHeaderContext;