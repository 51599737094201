import styles from "./rrxCoach.module.css";
import Heading from "./components/heading/Heading";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Tabs from "./components/tabs/Tabs";
import Search from "./components/search/Search";
import EmptyBag from "./components/emptyBag/EmptyBag";
import Pagination from "./components/pagination/Pagination"
import AddNewMemberForm from "./components/form/AddNewMemberForm";


const RrxCoach = () => {



    return (
        <div className={styles.mainContainer}>
            <PageHeaderContext />
            <Heading />
             <Tabs />
            <Search />
            <EmptyBag />
        </div>
    )
}

export default RrxCoach;