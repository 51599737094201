import styles from "./heading.module.css"
import { useState } from "react";

const Heading = () => {

    return (

        <div className={styles.heading}>
            <div className={styles.practiceLogo}>
                MB
            </div>
            <div className={styles.practiceName}>
                Maryanne Bruno
            </div>
        </div>

    )
}

export default Heading;